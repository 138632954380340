import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'

Vue.use(VueRouter)

let locale = navigator && navigator.language
locale = locale && ['en', 'ja'].find(e => locale.startsWith(e)) || i18n.fallbackLocale

const routes = [
  {
    path: '/',
    redirect: '/' + locale
  },
  {
    path: '/:lang',
    component: () => import('@/views/MainView'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home'),
      },
      {
        path: 'man/:id',
        name: 'Manage',
        component: () => import('@/views/ManagerView'),
        meta: {
          isWritable: true,
        },
        children: [
          {
            path: '/',
            name: 'Entrance',
            component: () => import('@/views/Entrance'),
          },
          {
            path: 'rule',
            name: 'Rule',
            component: () => import('@/views/Rule'),
          },
          {
            path: 'check-in',
            name: 'CheckIn',
            component: () => import('@/views/CheckIn'),
          },
          {
            path: 'leaderboard',
            name: 'Leaderboard',
            component: () => import('@/views/Leaderboard'),
          },
          {
            path: 'tables',
            name: 'Tables',
            component: () => import('@/views/Tables'),
          },
          {
            path: 'config',
            name: 'Config',
            component: () => import('@/views/Config'),
          },
        ]
      },
      {
        path: 'view/:name',
        name: 'Observe',
        component: () => import('@/views/ObserverView'),
        children: [
          {
            path: '/',
            name: 'Entrance',
            component: () => import('@/views/Entrance'),
          },
          {
            path: 'tables',
            name: 'Tables',
            component: () => import('@/views/Tables'),
          },
          {
            path: 'leaderboard',
            name: 'Leaderboard',
            component: () => import('@/views/Leaderboard'),
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  routes,
})

export default router
