import axios from 'axios'

const hooks = {
  ['event/setTableResult']: updateTable,
  ['event/addPlay']: updatePlayer,
  ['event/addPlayer']: updatePlayer,
  ['event/addPlayers']: updatePlayer,
  ['event/renamePlayer']: updatePlayer,
  ['event/deletePlayer']: updatePlayer,
  ['event/dropPlayer']: updatePlayer,
  ['event/setRule']: updateRule,
  ['event/startRound']: postState,
  ['event/finishRound']: postState,
}

async function postState({state}) {
  const key = state.event.id
  const data = {
    phrase: state.event.phrase,
    startTime: state.event.startTime,
    player: state.event.player,
    table: state.event.table,
    round: state.event.round,
    rule: state.event.rule,
  }
  return post(key, data)
}

async function updateTable({state}) {
  const key = state.event.id
  const data = {
    phrase: state.event.phrase,
    table: state.event.table,
  }
  return post(key, data)
}

async function updatePlayer({state}) {
  const key = state.event.id
  const data = {
    phrase: state.event.phrase,
    player: state.event.player,
  }
  return post(key, data)
}

async function updateRule({state}) {
  const key = state.event.id
  const data = {
    phrase: state.event.phrase,
    rule: state.event.rule,
  }
  return post(key, data)
}

async function post(key, data) {
  return axios.post(process.env.VUE_APP_BACKEND_HOST + '/api/man/' + key, data)
}

export default function() {
  return store => {
    store.subscribe((mutation, state) => {
      hooks[mutation.type] && hooks[mutation.type]({state})
      .then(() => {
        console.debug('auto save success.')
      })
      .catch(e => {
        console.error('persistent error: ' + e.stack)
      })
    })
  }
}
