import localStorage from '@/persistent/localstorage'

const state = {
  isCheckinReassign: false,
  skipRoundOverSafety: false,
  skipRoundAdvance: false,
}

try {
  const config = JSON.parse(localStorage.getItem('config'))
  if(config) {
    Object.assign(state, config)
    console.debug('config was loaded')
  }
} catch {
  console.warn('failure to get config data from localStorage')
}

export default {
  state,
  mutations: {
    setConfig(state, { prop, value }) {
      state[prop] = value
    },
  },
  actions: {
    setConfig({ commit, state }, { prop, value }) {
      commit('setConfig', { prop, value })
      localStorage.setItem('config', JSON.stringify(state))
    },
  }
}
