import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisH, faCheckCircle, faExclamationTriangle, faTimesCircle, faGripLines, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faBitbucket } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import './registerServiceWorker'
import i18n from './i18n'

library.add(faEllipsisH, faCheckCircle, faExclamationTriangle, faTimesCircle, faBitbucket, faGripLines, faLanguage)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

const toastOption = {
  timeout: 3000,
  closeOnClick: true,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
}

Vue.use(Vuelidate)
Vue.use(Toast, toastOption)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
