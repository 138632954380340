import Dexie from 'dexie'

const db = new Dexie('sdmDb')
db.version(1).stores({
  events: `++id, startTime`,
})

db.version(2).stores({
  eventHistory: '&id, &phrase, created',
})

export default db
