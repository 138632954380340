import Vue from 'vue'
import Vuex from 'vuex'
import config from './config'
import post from './plugins/post'
import db from '@/persistent/dexie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    backups: [],
    isWaitingRefresh: false,
  },
  mutations: {
    setBackups(state, backups) {
      state.backups = backups
    },
    waitRefresh(state) {
      state.isWaitingRefresh = true
    },
  },
  actions: {
    async initBackups({ commit }) {
      const arr = await db.eventHistory.orderBy('created').reverse().limit(10).toArray()
      commit('setBackups', arr)
      return arr.length && arr[0]
    },
  },
  modules: {
    config
  },
  plugins: [
    post()
  ]
})
